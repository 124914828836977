import { gql } from '@apollo/client';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppBar, Box, Grid, MenuItem, Snackbar, styled } from '@mui/material';
import { OnelifeResourceCenterTrigger } from '@onemedical/rna-onelife-resource-center-trigger';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { MouseEvent, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { debounce } from 'lodash';
import Logo from '../components/Logo';
import AppointmentsMenuItems from './AppointmentsMenuItems';
import NavButton from './NavButton';
import NavMenuWrapper from './NavMenuWrapper';
import OutOfOfficePopup from './OutOfOfficePopUp';
import PatientSearchBox from './PatientSearchBox';
import ProviderStatusPopup from './ProviderStatusPopup';
import UserMenu from './UserMenu';
import { useProfile } from './hooks/useProfile';
import { useProviderStatus } from './hooks/useProviderStatus';
import { InternalUser } from './models/InternalUser';
import SchedulingMenuItems from './SchedulingMenuItems';
import { ONELIFE_URL } from './utils/constants';
import mixpanel from '../mixpanel';

const defaultMinWidth = 1155;
const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.grey[800],
  padding: theme.spacing(0, 2),
  minWidth: `${defaultMinWidth}px`,
  zIndex: theme.zIndex.tooltip,
}));

const StyledGrid = styled(Grid)(() => ({
  height: '40px',
  overflow: 'hidden',
  flexWrap: 'nowrap',
}));

interface NavBarProps {
  profile: InternalUser;
}

type MenuType = 'appointment' | 'scheduling' | 'panel' | 'template';
function NavBar({ profile }: NavBarProps) {
  const { enableBirdwatchingBoard, omniMsa, trackClicksOnNavBarDocsLink } = useFlags();

  const [openNavMenu, setOpenNavMenu] = useState<{
    menu: MenuType;
    element: HTMLElement;
  } | null>(null);

  const toggleMenu = (menuType: MenuType) => (event: MouseEvent<HTMLElement>) => {
    setOpenNavMenu({ menu: menuType, element: event.currentTarget });
  };

  const handleClose = () => {
    setOpenNavMenu(null);
  };

  const serviceAreaQueryParam = (profile?.serviceArea?.serviceAreaGroup?.serviceAreas || [])
    .map((sa) => `service_area[]=${sa.id}-ServiceArea`)
    .join('&');

  const { hasRole } = useProfile();
  const isAdmin = hasRole('ADMIN');
  const isProvider = hasRole('PROVIDER');
  const legacyDocsLink = `${ONELIFE_URL}/admin/inbox?${serviceAreaQueryParam}`;

  const [dimensions, setDimensions] = useState({
    width: defaultMinWidth,
  });
  const { workSchedule } = useProviderStatus();
  const [canShowProviderOOOModal, setCanShowProviderOOOModal] = useState(true);
  const [providerStatusVisible, setProviderStatusVisible] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [currentProviderStatus, setCurrentProviderStatus] = useState<boolean | null>(null);

  // Width could be any one of these depending on the content and browser
  // https://javascript.info/size-and-scroll-window#width-height-of-the-document
  // Not all of them update properly upon resizing to SMALLER window, so taking minimum width of these seems more reliable
  const minWidth = Math.min(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.body.clientWidth,
    document.documentElement.clientWidth,
  );

  useEffect(() => {
    let trackingTimeout: undefined | NodeJS.Timeout;

    if (!trackClicksOnNavBarDocsLink) {
      trackingTimeout = undefined;
    } else {
      // mixpanel.trackLinks has to be called after the target element loads, hence setTimeout
      // See useEffect Caveats for more info: https://react.dev/reference/react/useEffect#caveats
      trackingTimeout = setTimeout(
        () =>
          mixpanel.trackLinks('#docs-nav-button', 'Nav Button Clicked', {
            workflow: 'Nav Bar',
            component: 'Onelife Container Nav Bar',
            subcomponent: 'Docs',
          }),
        1000,
      );
    }

    // clearTimeout() does nothing if trackingTimeout is undefined
    // [Docs](https://developer.mozilla.org/en-US/docs/Web/API/Window/clearTimeout)
    return () => clearTimeout(trackingTimeout);
  }, [trackClicksOnNavBarDocsLink]);

  useEffect(() => {
    // Use minWidth for reason above unless it is set to 0 due to browser/content
    let width = minWidth;
    if (minWidth === 0)
      width = Math.max(
        document.body.scrollWidth,
        document.documentElement.scrollWidth,
        document.body.offsetWidth,
        document.documentElement.offsetWidth,
        document.body.clientWidth,
        document.documentElement.clientWidth,
      );

    const debouncedHandleResize = debounce(() => {
      setDimensions({
        width,
      });
    }, 250);

    setDimensions({ width });

    window.onresize = () => {
      debouncedHandleResize();
    };
  }, [minWidth]);

  useEffect(() => {
    if (currentProviderStatus) {
      setCanShowProviderOOOModal(false);
    }
  }, [currentProviderStatus]);

  return (
    <StyledAppBar position="sticky" sx={{ ...dimensions }}>
      <StyledGrid container alignItems="center">
        <Grid item>
          <Logo />
        </Grid>
        <Grid item>
          <NavButton color="inherit" component={NavLink} to="/schedule/tasks" data-cy="tasks-nav">
            Tasks
          </NavButton>
        </Grid>
        {omniMsa && (
          <Grid item>
            <NavButton
              color="inherit"
              onClick={toggleMenu('scheduling')}
              endIcon={<FontAwesomeIcon icon={faAngleDown} data-cy="scheduling-menu-nav" />}
            >
              Scheduling
            </NavButton>
            <NavMenuWrapper
              id="scheduling-menu"
              anchorEl={openNavMenu?.element}
              open={openNavMenu?.menu === 'scheduling'}
              onClose={handleClose}
            >
              <SchedulingMenuItems profile={profile} handleClose={handleClose} />
            </NavMenuWrapper>
          </Grid>
        )}
        <Grid item>
          <NavButton
            color="inherit"
            onClick={toggleMenu('appointment')}
            endIcon={<FontAwesomeIcon icon={faAngleDown} data-cy="appointments-menu-nav" />}
          >
            Appointments
          </NavButton>
          <NavMenuWrapper
            id="appointments-menu"
            anchorEl={openNavMenu?.element}
            open={openNavMenu?.menu === 'appointment'}
            onClose={handleClose}
          >
            <AppointmentsMenuItems profile={profile} handleClose={handleClose} />
          </NavMenuWrapper>
        </Grid>
        <Grid item>
          <NavButton id="docs-nav-button" color="inherit" href={legacyDocsLink} data-cy="docs-nav">
            Docs
          </NavButton>
        </Grid>
        <Grid item>
          <NavButton color="inherit" href={`${ONELIFE_URL}/fax/send_fax`} data-cy="faxes-nav">
            Faxes
          </NavButton>
        </Grid>
        <Grid item>
          <NavButton
            color="inherit"
            component={NavLink}
            to="/admin/contacts"
            data-cy="contacts-nav"
          >
            Contacts
          </NavButton>
        </Grid>
        <Grid item>
          <NavButton
            color="inherit"
            component={NavLink}
            to="/admin/provider-directory"
            data-cy="providers-nav"
          >
            Providers
          </NavButton>
        </Grid>
        <Grid item>
          <NavButton
            color="inherit"
            onClick={toggleMenu('template')}
            endIcon={<FontAwesomeIcon icon={faAngleDown} data-cy="template-manager-nav" />}
          >
            Template Manager
          </NavButton>
          <NavMenuWrapper
            id="template-menu"
            anchorEl={openNavMenu?.element}
            open={openNavMenu?.menu === 'template'}
            onClose={handleClose}
          >
            <MenuItem
              color="inherit"
              component="a"
              href={process.env.REACT_APP_TEMPLATE_MANAGER_URL}
              data-cy="Template Manager"
            >
              Template Manager
            </MenuItem>
            <MenuItem
              color="inherit"
              component={NavLink}
              to="/admin/pap-reporting"
              data-cy="pap-reporting-nav"
              onClick={handleClose}
            >
              Cervical Cancer Screening (Pap) Reporting
            </MenuItem>
          </NavMenuWrapper>
        </Grid>
        <Grid item>
          <NavButton
            color="inherit"
            onClick={toggleMenu('panel')}
            endIcon={<FontAwesomeIcon icon={faAngleDown} data-cy="panel-management-nav" />}
          >
            Panel Management
          </NavButton>
          <NavMenuWrapper
            id="panel-menu"
            anchorEl={openNavMenu?.element}
            open={openNavMenu?.menu === 'panel'}
            onClose={handleClose}
          >
            <MenuItem
              component={NavLink}
              exact
              to="/panel-management/home"
              data-cy="panel-nav"
              onClick={handleClose}
            >
              Panel Management Home
            </MenuItem>
            <MenuItem
              component={NavLink}
              to="/panel-management/admissions/"
              data-cy="admissions-nav"
              onClick={handleClose}
            >
              Admissions Board
            </MenuItem>
            {enableBirdwatchingBoard && (
              <MenuItem
                component={NavLink}
                to="/panel-management/birdwatching/"
                data-cy="birdwatching-board-nav"
                onClick={handleClose}
              >
                Birdwatching Board
              </MenuItem>
            )}
          </NavMenuWrapper>
        </Grid>
        <Box flexGrow={1} />
        <Grid item sx={{ marginRight: 1, lineHeight: 1 }}>
          {/* TODO: nonce shouldn't be required */}
          <OnelifeResourceCenterTrigger nonce={undefined} />
        </Grid>
        <Grid item sx={{ marginRight: 1 }}>
          <PatientSearchBox isAdmin={isAdmin} data-cy="patient-search-nav" />
        </Grid>
        <Grid item>
          {profile && (
            <UserMenu
              profile={profile}
              currentRoles={profile.roles}
              data-cy="profile-name-nav"
              passProviderStatusVisibility={setProviderStatusVisible}
              currentlyOut={currentProviderStatus}
            />
          )}
        </Grid>
      </StyledGrid>
      <Box>
        {isProvider && providerStatusVisible && (
          <ProviderStatusPopup
            passProviderStatusVisibility={setProviderStatusVisible}
            setOpenToast={setOpenToast}
            setToastMessage={setToastMessage}
            setCurrentlyOut={setCurrentProviderStatus}
          />
        )}
        {isProvider && canShowProviderOOOModal && workSchedule && workSchedule.returningOn && (
          <OutOfOfficePopup
            setCurrentlyOut={setCurrentProviderStatus}
            onClose={() => setCanShowProviderOOOModal(false)}
          />
        )}
      </Box>
      <Snackbar
        data-cy="provider-status-toast"
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={openToast}
        message={toastMessage}
        onClose={() => setOpenToast(false)}
        sx={{ color: 'black' }}
      />
    </StyledAppBar>
  );
}

NavBar.fragments = {
  profile: gql`
    fragment NavBarProfile on InternalUser {
      roles {
        id
      }
      homeOffice {
        id
      }
      serviceArea {
        id
        serviceAreaGroup {
          id
          serviceAreas {
            id
          }
        }
      }
    }
  `,
};

export default NavBar;
